<template>
  <div class="media">
    <v-row>
      <v-col cols="12">
        <div
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
          class="d-flex justify-between media__header"
        >
          <span class="heading-4">Medical records</span>
          <v-text-field
            :placeholder="$t('patients.searchPlaceholder')"
            :value="name"
            class="media__input"
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
            @change="changeName"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="media__container">
          <v-row>
            <v-col class="media__title-wrap" cols="12">
              <div class="title">Attachments</div>
              <v-select
                :items="selectValues"
                :value="fileType"
                class="media__select"
                dense
                hide-details="true"
                outlined
                placeholder="Day"
                @change="changeFileType($event)"
              ></v-select>
            </v-col>
          </v-row>
          <MediaUploader />
          <v-progress-linear v-if="loading" color="primary" indeterminate />
          <div v-if="files.length" class="media-list">
            <v-card class="mx-auto">
              <v-list three-line>
                <v-list-item v-for="file in files" :key="file.id" class="media-list__item">
                  <v-list-item-avatar class="media-list__image" rounded="sm" size="125">
                    <v-img :src="file.url" @click="show(file.url)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ createdData(file.createDate) }}
                      <v-icon>mdi-circle-small</v-icon>
                      {{ file.creator.displayName }}
                      <v-icon>mdi-circle-small</v-icon>
                      {{ file.mimetype }}
                      <v-icon>mdi-circle-small</v-icon>
                      {{ getSize(file.size) }}
                    </v-list-item-subtitle>
                    <div class="media-list__actions">
                      <div v-if="file.fhirId" class="media-list__clinical">
                        <v-icon>$vuetify.icons.radioChecked</v-icon>
                        <span> Clinical file</span>
                      </div>
                      <PrimaryButton
                        :loading="file.isLoadingRename"
                        className="primary-btn__secondary-blue"
                        leftIcon="mdi-grease-pencil"
                        text="Rename"
                        @onClick="onFileEdit(file)"
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
            <MediaRename
              v-model="mediaRenameDialog"
              :file="editFile"
              @onChange="onFileChange"
              @toggleDialog="toggleRenameDialog"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import MediaRename from "@/components/media/MediaRename";
import MediaUploader from "@/components/media/MediaUploader";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "Index",
  components: { MediaRename, MediaUploader, PrimaryButton },
  data: () => {
    return {
      mediaRenameDialog: false,
      editFile: {},
      selectValues: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Documents",
          value: "document",
        },
      ],
      settings: {
        dots: false,
        arrows: true,
        centerMode: true,
        centerPadding: "20px",
        variableWidth: true,
      },
      loading: false,
      slideOptions: {
        prevNextButtons: true,
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMediaStore, ["files", "fileType", "name"]),
  },
  methods: {
    ...mapActions(useMediaStore, ["fetchFiles", "emptyFiles", "setFileType", "setName"]),
    ...mapActions(useMediaStore, ["setUid"]),
    async changeName(value) {
      this.loading = true;
      this.setName(value);
      await this.fetchFiles();
      this.loading = false;
    },
    async changeFileType(value) {
      this.loading = true;
      await this.setFileType(value);
      await this.fetchFiles();
      this.loading = false;
    },
    getSize: (size) => {
      return `${parseInt(size / 1024)} KB`;
    },
    show(image) {
      this.$viewerApi({
        images: [image],
      });
    },
    async toggleRenameDialog() {
      this.mediaRenameDialog = false;
      await this.fetchFiles();
    },
    async onFileChange(file) {
      this.mediaRenameDialog = false;
      const files = this.files.map((t) => ({ ...t, isLoadingRename: file.id === t.id }));
      this.setFiles(files);
      await this.fetchFiles();
    },
    onFileEdit(file) {
      this.editFile = file;
      this.mediaRenameDialog = true;
    },
    createdData(date) {
      return date && moment(date).isValid()
        ? moment(date).isSame(new Date(), "day")
          ? moment(date).fromNow()
          : moment(date).format("dd MMMM yyyy")
        : "";
    },
  },
  async mounted() {
    await this.setUid(this.uid);
    await this.fetchFiles();
  },
};
</script>

<style lang="scss">
.media {
  background: #fafafa;
  padding: 0 30px;

  &__input {
    max-width: 280px;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .heading-4 {
    padding-top: 28px;
    padding-bottom: 36px;
    font-weight: 550;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }

  &__select {
    max-width: 115px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #25233a;
      opacity: 0.4;
    }

    .link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: var(--primary);
      cursor: pointer;
    }
  }

  &__container {
    background: white;
    padding: 15px 20px;
    box-shadow: none !important;
  }
}

.media-list {
  overflow: auto;

  .primary-btn__secondary-blue {
    padding: 8px 10px 9px !important;
    background: #eff6ff !important;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px;
    color: var(--primary);

    i {
      font-size: 12px !important;
    }

    &:hover {
      background: #eff6ff !important;
      opacity: 0.8;
    }
  }

  &__image {
    cursor: pointer;
    width: 125px;
    aspect-ratio: 1;
  }

  &__actions {
    display: flex;
    gap: 15px;
  }

  &__clinical {
    cursor: inherit;
    display: flex;
    align-items: center;
    color: $primary !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__item {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    &:hover {
      background: rgba(239, 246, 255, 0.4);
    }

    .v-list-item__content {
      align-items: flex-start;
    }
  }

  .v-card__title {
    line-height: 1;
    padding: 8px 16px;
  }
}
</style>
